import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "-ai-robot-hand-with-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#-ai-robot-hand-with-raspberry-pi",
        "aria-label": " ai robot hand with raspberry pi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🤖 AI Robot Hand with Raspberry Pi`}</h1>
    <blockquote>
    <p>
        <center>
            <strong>A robotics hand that mimics human hands using Computer Vision</strong>
        </center>
    </p>
    </blockquote>
    <p><img parentName="p" {...{
        "src": "banner-1920.png",
        "alt": "banner-1920"
      }}></img></p>
    <div style={{
      "overflowX": "auto"
    }}>
    <table>
        <thead>
            <tr>
                <th>
                    <center>
                        This document is for <a href="https://github.com/DEVHEE/ai-robot-hand-with-raspberry-pi">DEVHEE/ai-robot-hand-with-raspberry-pi</a> project.
                    </center>
                </th>
            </tr>
        </thead>
    </table>
    </div>
    <p>{`It contains all the progress from beginning to end of the project. I solved problems through challenges and failures, and I tried to create more complete projects through trial and error. This document is an English version, and the Korean version can be found on the `}<a parentName="p" {...{
        "href": "https://blog.kimdonghee.dev/categories/robot/"
      }}>{`Tech Blog (BLOG.KIMDONGHEE.DEV)`}</a>{`. Information about the project can be obtained from `}<a parentName="p" {...{
        "href": "https://github.com/DEVHEE/ai-robot-hand-with-raspberry-pi/blob/main/README.md"
      }}>{`README`}</a>{`.`}</p>
    <p>{`There are a few things you can learn from this document.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Electronic Control`}</p>
        <p parentName="li">{`You can learn about serial communication such as I2C. Through servomotor control, you can know the basic structure of operating principles and control methods, and you can also practice directly using PWM.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hardware`}</p>
        <p parentName="li">{`This project basically uses a Raspberry Pi board, so you can learn about its structure and operation. In addition, through hardware expansion through GPIO, you can know the role and control of each module. On top of that, you can look at the circuit diagram and data sheet of each module to understand how it works and apply it to practice.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Software`}</p>
        <p parentName="li">{`You can learn about various artificial intelligence (machine learning and deep learning) technologies. Use Convolutional Neural Network learning algorithms to learn data and apply it to Raspberry Pie. We explore the field of Computer Vision, including OpenCV, and even study optimization methods for microcontrollers. We also learn how to apply to various fields using other libraries.`}</p>
      </li>
    </ul>
    <p><em parentName="p">{`This document is written for students and developers who want to get information and study. Unauthorized reproduction or distribution of this program, or any portion of it, and commercial use may result in severe civil and criminal penalties and will be prosecuted to the maximum extent possible under the law.`}</em></p>
    <p><img parentName="p" {...{
        "src": "https://img.shields.io/static/v1?style=for-the-badge&label=CREATED%20BY&message=KIM%20DONGHEE&color=000000",
        "alt": "CREATED BY KIM DONGHEE"
      }}></img>{` `}<img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/46535278/133321202-73a5c373-1418-429a-8b7a-df390bb16fd8.png",
        "alt": "CC BY-NC-ND"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      